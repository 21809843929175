import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import Insurance from "../components/Solutions/Insurance/Insurance";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/insurance.jpg"

const InsurancePage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Insurance - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Drivosity and Intrepid Direct Insurance offer tailored risk management and insurance solutions for restaurant delivery services. Enjoy up to 25% savings on Hired & Non-Owned Auto insurance and experience a reduction in insurance claims. Learn more today!"/>

                <meta property="og:title" content="Insurance - Drivosity"/>
                <meta property="og:description" content="Drivosity and Intrepid Direct Insurance offer tailored risk management and insurance solutions for restaurant delivery services. Enjoy up to 25% savings on Hired & Non-Owned Auto insurance and experience a reduction in insurance claims. Learn more today!"/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="Two professionals discussing financial charts on a laptop, symbolizing insurance, financial planning, and risk management."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/insurance`}/>
            </Helmet>
            <Layout component={<Insurance/>}/>
        </>
    )
}

export default InsurancePage;
